import styled from '@emotion/styled';
import { BANNER_OPACITY } from '../../../utils/common';

export const MiddleBodyVideoBackground = styled.div`
  background: ${(props) =>
    !props?.propsBackground?.content[0]?.url && `none, ${props?.themeBackground}`};
  background-size: cover;
  background-position: center;
  flex-direction: column;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 0; /* Set the z-index behind other content */
  }

  /* Add a linear gradient overlay */
  &::after {
    content: '';
    content: '';
    background: ${(props) => props?.themeBackground};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: ${BANNER_OPACITY};
  }
  @media screen and (min-width: 768px) {
    & > video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; /* Scale the video while maintaining its aspect ratio */
    }
  }
  @media screen and (max-width: 767px) {
    & > video {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover; /* Scale the video while maintaining its aspect ratio */
    }
  }

  & video {
    position: absolute;
    height: 100%;
  }
`;
export const MiddleBodyParent = styled.div`
  display: flex;
  width: 100%;
  z-index: 2;
  flex-direction: column;

  &.bg_type_image {
    background: ${(props) =>
      props?.propsBackground?.content[0]?.url
        ? `url(${props?.propsBackground?.content[0]?.url})`
        : `none, ${props?.themeBackground}`};
    background-size: cover;
    background-position: center;
  }

  &.bg_type_solid {
    background: ${(props) => props?.themeBackground};
  }
  &.bg_type_image::after,
  .bg_type_solid {
    content: '';
    background: ${(props) => props?.themeBackground};
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: ${BANNER_OPACITY};
    z-index: 1;
  }
  @media screen and (min-width: 768px) {
    gap: 4rem;
    padding: 4rem 0rem;
  }
  @media screen and (max-width: 767px) {
    gap: 1.5rem;
    padding: 2rem 0;
  }
`;

export const TopSection = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;

  @media screen and (min-width: 768px) {
    gap: 4rem;
    padding: 0 4rem;
  }
  @media screen and (max-width: 767px) {
    gap: 0;
    padding: 0 1rem;
  }
`;

export const TopLeftSection = styled.div`
  flex: 1 0 auto;
  width: 100%;
  & > h1 {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    width: 100%;
    margin: 0;
    color: ${(props) => props?.textStyles?.heading?.color};
    font-family: ${(props) => props?.textStyles?.heading?.fontFamily || 'Arial'};
    text-align: ${(props) => props?.textStyles?.heading?.textAlign || 'left'};
  }

  @media screen and (min-width: 768px) {
    & > h1 {
      font-size: ${(props) => props?.textStyles?.heading?.fontSize || '3.25rem'};
      font-weight: ${(props) => props?.textStyles?.heading?.fontWeight || '500'};
      line-height: 3.875rem;
    }
  }
  @media screen and (max-width: 767px) {
    & > h1 {
      font-size: 1.5rem;
      font-weight: 700;
      line-height: normal;
    }
  }
`;

export const TopRightSection = styled.div`
  flex: 0;
  width: 0;
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const BottomSection = styled.div`
  .slider__parent {
    display: block;
    width: 100%;
    height: auto !important;
  }
  .slick-list {
    height: auto !important;
  }
  .slider__parent .slick-slider .slick-list .slick-track .slick-slide {
    padding-right: 1rem;
  }
  .slider__parent .slick-slider .slick-list .slick-track .slick-slide div {
    aspect-ratio: 4/3 !important;
  }
  .slider__parent .slick-slider .slick-list .slick-track .slick-slide div .slider-media {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    justify-content: center;
    .slide-image-container {
      max-width: 100%;
      max-height: 100%; /* Adjust this value as needed for your aspect ratio */
      position: relative;
      img {
        margin: auto 1rem 0rem 0rem;
        max-width: 100%;
        max-height: 100%;
        width: 100%;
        aspect-ratio: 4/3;
      }

      p {
        color: ${(props) => props?.textStyles?.body?.color};
        text-align: ${(props) => props?.textStyles?.body?.textAlign || 'center'};
        font-family: ${(props) => props?.textStyles?.body?.fontFamily || 'Arial'};
        font-size: ${(props) => props?.textStyles?.body?.fontSize || '1.125rem'};
        font-weight: ${(props) => props?.textStyles?.body?.fontWeight || '400'};
      }
      @media screen and (max-width: 767px) {
        p {
          font-size: 1rem;
        }
      }
    }
  }
  .slider__parent .arrow-group {
    display: flex;
    gap: 1rem;
    width: 100%;
    justify-content: flex-end;
    position: absolute;
    top: 86px;
    right: 43px;
    color: white;
  }
  .slider__parent .arrow-group button {
    color: white;
    cursor: pointer;
  }
  .slider__parent .arrow-group button svg path {
    fill: currentColor;
  }
  .slider__parent .slick-slider ul {
    display: none !important;
  }
  @media screen and (min-width: 768px) {
    padding-left: 4rem;
    .slider__parent
      .slick-slider
      .slick-list
      .slick-track
      .slick-slide
      div
      .slider-media {
      .slide-image-container {
        max-width: 100%;
        max-height: 100%; /* Adjust this value as needed for your aspect ratio */
        position: relative;
      }
    }
  } //
  @media screen and (max-width: 767px) {
    padding-left: 1rem;

    .slider__parent
      .slick-slider
      .slick-list
      .slick-track
      .slick-slide
      div
      .slider-media {
      .slide-image-container {
        max-height: 100%;
        position: relative;
        p {
          font-size: 0.875rem;
        }
      }
    }
    .slider__parent .arrow-group {
      display: none !important;
    }
  }
`;
